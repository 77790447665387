@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&family=Poppins:wght@400;600&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: AppleSDGothicNeo, sans-serif;
  background-color: var(--grey8);
}

:root {
  --orange1: #e73d21;
  --orange2: #fc795e;
  --orange3: #fdb495;
  --orange4: #fbdace;
  --orange5: #ffede6;
  --orange6: #fefaf4;
  --yellow1: #ffd557;
  --green1: #26a089;
  --green2: #3ab39c;
  --green3: #3fcfa4;
  --green5: #e2f2ed;
  --blue1: #0071f3;
  --blue2: #1f8efe;
  --blue3: #75b5ff;
  --blue4: #b4d0f0;
  --blue5: #eaf3ff;
  --blue6: #f8fbff;
  --pink1: #fa4c4b;
  --pink2: #ff7b88;
  --pink3: #ffadb5;
  --pink4: #f8ccd0;
  --pink5: #f9e8ea;
  --pink6: #fff8f8;
  --brown1: #64422a;
  --brown2: #946442;
  --brown3: #bd9e6f;
  --brown4: #e5c9b1;
  --brown5: #f9f2e7;
  --brown6: #fffbf3;
  --violet1: #6833d8;
  --violet2: #9b67dd;
  --violet5: #f4eaff;
  --violet6: #fcf9ff;
  --grey1: #000;
  --grey2: #222;
  --grey3: #333;
  --grey4: #666;
  --grey5: #999;
  --grey6: #d6d6d6;
  --grey7: #e6e6e6;
  --grey8: #f5f5f5;
  --grey9: #fafafa;
  --grey10: #ffffff;
  color: var(--grey2);
  color-scheme: light only;
}

* {
  font-family: inherit;
  box-sizing: border-box;
  user-select: none; /* 드래그, 더블탭으로 드래그 막기 */
  -webkit-touch-callout: none;  /* link long touch 막기 */
  letter-spacing: -0.04em;
  line-height: 1.7;
}

h1, h2, h3, h4, h5, h6, p {
  font-weight: normal;
  margin-bottom: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

h1 {
  font-size: 20px;
}
h2 {
  font-size: 18px;
}
h3 {
  font-size: 16px;
}
h4 {
  font-size: 14px;
}
h5 {
  font-size: 12px;
}

a {
  color: var(--grey2);
  text-decoration: none;
}

label {
  display: block;
}

input {
  font-size: 16px;
  -webkit-user-select: text;
  user-select: text;
}

input::placeholder {
  color: var(--grey6);
}

input[type=text], input[type=tel] {
  padding: 12px;
  border: 1px solid var(--grey7);
  width: 100%;
  height: 50px;
  border-radius: 4px;
  -webkit-user-select: text;
}

input[type=text], input[type=tel], textarea {
  padding: 12px;
  border: 1px solid var(--grey7);
  width: 100%;
  border-radius: 4px;
  -webkit-user-select: text;
}

input[type=text]:focus, input[type=tel]:focus, textarea:focus {
  outline: none;
  border-color: var(--grey2);
}

input[type=text]:disabled, input[type=tel]:disabled, textarea:disabled {
  border-color: var(--grey7);
  background-color: var(--grey9);
  color: var(--grey6);
}

button {
  background-color: var(--grey10);
  border: 1px solid var(--grey7);
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  word-break: keep-all;
  white-space: nowrap;
  height: 45px;
  padding: 10px;
}

button:hover {
  border: 1px solid var(--grey2);
  font-weight: 500;
}

img {
  max-width: 100%;
  vertical-align : bottom
}

video,iframe {
  border: none;
  vertical-align : bottom
}

.flex {
  display: flex;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.center {
  display: flex;
  justify-content: center;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.position-relative {
  position: relative;
}

.underlined {
  text-decoration: underline;
}

.fit-content {
  width: fit-content;
}

::-webkit-scrollbar {
  opacity: 0;
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--grey6);
  border-radius: 10px;
}